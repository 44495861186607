.App {
  font-family: 'Poppins', sans-serif;
  letter-spacing: .5px;
 

}

.App-logo {
  height: 80vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.main {

  background-color: #F3F4F6;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  font-size: calc(10px + 2vmin);
  margin-top: 120px;

}

.App-link {
  color: #61dafb;
}
.paragraph {
  color: white;

  font-size: 16px;
  line-height: 18px;
  max-width: 270px;
  padding: 8px;
  text-align: end;
}

.header {
  width: 100%;
  height: 100px; /* Adjust height as needed */
  background-color: #F3F4F6; /* Example background color */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000; /* Ensure it appears above other content */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px; /* Adjust padding as needed */
  box-sizing: border-box;
}

.header__logo {
  width: 100px; 
  object-fit: fill; 

  padding: 10px;
}
.header__menu {
  /* Styles for the menu */
}

.header_text {
  color: #78891d;
  font-size: 24px;
}

.input_box{
  background-color: white;
  flex: auto;
  flex-direction: row;
  padding: 20px;

}

.h3 {
  color: #343a40;
  font-size: 18px;
  margin-bottom: 20px;


}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 480px) {
  .header {
  
    height: 80px;
  }
  .main {
    margin-top: 10px;

  }

  .header__logo {
    width: 80px;
    padding: 10px
  }

  .header_text {
    font-size: 20px;
  }
}