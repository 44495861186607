/* Register.css */

/* Base styles */
body {
    font-family: 'Poppins', sans-serif;
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120vh;
  }
  
  .register-container {
    background-color: #fff;
    padding: 20px 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;

  }
  
  .register-container h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    letter-spacing: 1px;
  }
  
  .input_form {
    display: flex;
    flex-direction: column;
  }
  
  .input_form input {
    font-family: 'Poppins', sans-serif;
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .input_form input:focus {
    border-color: #800704;
    outline: none;
  }
  
  .input_form button {
    font-family: 'Poppins', sans-serif;
    padding: 10px;
    background-color: #c00b08;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .input_form button:hover {
    background-color: #800704;
  }
  
  /* Media Queries */
  
  /* Tablet styles */
  @media (max-width: 768px) {
    .register-container {
      padding: 15px 30px;
    }
  
    .register-container h1 {
      font-size: 1.75rem;
    }
  
    .input_form input {
      padding: 8px;
      font-size: 0.875rem;
    }
  
    .input_form button {
      padding: 8px;
      font-size: 0.875rem;
    }
  }
  
  /* Phone styles */
  @media (max-width: 480px) {
    .register-container {
      padding: 10px 20px;
    }
  
    .register-container h1 {
      font-size: 1.5rem;
    }
  
    .input_form input {
      padding: 6px;
      font-size: 0.75rem;
    }
  
    .input_form button {
      padding: 6px;
      font-size: 0.75rem;
    }
  }